import { graphql } from "gatsby";
import React from "react";
import BlockZone from "~blockZone";
import Layout from "../components/layout";
import { PageMeta } from "../components/pageMeta";

const IndexPage = ({ data: { page } }) => {
  const { meta, pageBlocks } = page || {};

  return (
    <Layout headerReversed={true}>
      <div>
        <PageMeta {...meta} />
        {pageBlocks && <BlockZone {...pageBlocks} />}
      </div>
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query homePageQuery {
    page: sanityHomePage {
      meta {
        ...MetaCardFields
      }
      pageBlocks {
        ...BlockZone
      }
      bg
    }
  }
`;
